<script setup>
import {ref} from "vue";

let show = ref(false);
</script>

<template>
  <div class="fixed bottom-0 z-50 w-full flex flex-col p-1" v-if="show">
    <div class="flex justify-between items-center">
      <div class="text-center text-base-300-content font-bold p-1">
        <span class="sm:hidden">Breakpoint: xs</span> <span class="hidden sm:block md:hidden">Breakpoint: sm</span>
        <span class="hidden md:block lg:hidden">Breakpoint: md</span> <span class="hidden lg:block xl:hidden">Breakpoint: lg</span>
        <span class="hidden xl:block 2xl:hidden">Breakpoint: xl</span> <span
          class="hidden 2xl:block">Breakpoint: 2xl</span>
      </div>

      <div class="text-center text-base-300-content font-bold">
        <label class="swap swap-rotate">

          <!-- this hidden checkbox controls the state -->
          <input type="checkbox" class="theme-controller hidden" value="dark"/>

          <!-- sun icon -->
          <svg class="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/>
          </svg>

          <!-- moon icon -->
          <svg class="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/>
          </svg>
        </label>
      </div>
    </div>

    <div class="flex justify-between">
      <div class="bg-primary p-1 text-primary-content">Primary</div>
      <div class="bg-secondary p-1 text-secondary-content">Secondary</div>
      <div class="bg-accent p-1 text-accent-content">Accent</div>
      <div class="bg-neutral p-1 text-neutral-content">Neutral</div>
      <div class="bg-base p-1 text-base-content">Base</div>
      <div class="bg-base-100 p-1 text-base-100-content">Base 100</div>
      <div class="bg-base-200 p-1 text-base-200-content">Base 200</div>
      <div class="bg-base-300 p-1 text-base-300-content">Base 300</div>
      <div class="bg-info p-1 text-info-content">Info</div>
      <div class="bg-success p-1 text-success-content">Success</div>
      <div class="bg-warning p-1 text-warning-content">Warning</div>
      <div class="bg-error p-1 text-error-content">Error</div>
    </div>
  </div>
  <div>
    <button @click="show = !show"
            class="fixed bottom-20 left-0 z-50 p-1 m-2 bg-primary text-primary-content rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"/>
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
      </svg>
    </button>
  </div>
</template>
